import { render, staticRenderFns } from "./TeamMembersList.vue?vue&type=template&id=21820189"
import script from "./TeamMembersList.vue?vue&type=script&lang=js"
export * from "./TeamMembersList.vue?vue&type=script&lang=js"
import style0 from "./TeamMembersList.vue?vue&type=style&index=0&id=21820189&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports