<template>
  <b-modal modal-class="modal_basic modalAddTeam" id="modalAddTeam" hide-footer hide-header>
    <img @click="close()" class="close_modal" src="/assets/img/cross.svg" alt="">

    <div class="basic_form modal_content" v-on-clickaway="close">

      <div class="heading">

        <h3 v-if="getTeam.id">Edit Team Member</h3>
        <h3 v-else>Add Team Member</h3>
      </div>

      <form class="  clear">
        <div class="row">
          <div class="col-md-6 pl-0 pr-1">
            <div class=" mt-3 input_icon_text d-flex align-items-center profile__box"
                 :class="{'input-field-error': validations.first_name || validations.first_name_length}">
              <div class="content">
                <div class="url">First Name</div>
                <div class="name">
                  <div class="validation_input">
                    <input type="text" v-model="getTeam.first_name"
                           placeholder="e.g Clark"
                           :readonly="getTeam.id != ''" data-cy="first-name">

                  </div>
                </div>
              </div>
            </div>
            <span class="input-error" v-if="validations.first_name">{{ messages.first_name }}</span>
            <span class="input-error" v-else-if="validations.first_name_length">{{ messages.first_name_length }}</span>
          </div>
          <div class="col-md-6 pr-0 pl-1">
            <div class=" mt-3 input_icon_text d-flex align-items-center profile__box"
                 :class="{'input-field-error': validations.last_name || validations.last_name_length}">
              <div class="content">
                <div class="url">Last Name</div>
                <div class="name">
                  <div class="validation_input">
                    <input type="text" v-model="getTeam.last_name"
                           placeholder="e.g Frederick"
                           :readonly="getTeam.id != ''" data-cy="last-name">
                  </div>
                </div>
              </div>
            </div>
            <span class="input-error" v-if="validations.last_name">{{ messages.last_name }}</span>
            <span class="input-error" v-if="validations.last_name_length">{{ messages.last_name_length }}</span>
          </div>
        </div>

        <div class="row">

          <div class="col-md-6 pl-0 pr-1">
            <div class=" mt-3 input_icon_text d-flex align-items-center profile__box"
                 :class="{'input-field-error': validations.email || validations.email_length}">
              <div class="content">
                <div class="url">Email Address</div>
                <div class="name">
                  <div class="validation_input">
                    <input type="email" v-model="getTeam.email"
                           placeholder="e.g clark.frederick@mywebsite.com"
                           :readonly="getTeam.id != ''" data-cy="t-email">
                  </div>
                </div>
              </div>
            </div>
            <span class="input-error" v-if="validations.email">{{ messages.email }}</span>
            <span class="input-error" v-if="validations.email_length">{{ messages.email_length }}</span>
          </div>

          <div class="col-md-6 pr-0 pl-1" v-if="getTeam.role !== ROLES.super_admin">
            <div class=" mt-3 input_icon_text d-flex align-items-center profile__box">
              <div class="content">
                <div class="url">Member role</div>
                <div class="name">
                  <div class="validation_input">
                    <select name="" v-model="getTeam.role" data-cy="t-role">
                      <option :selected="getTeam.role===ROLES.admin" :value="ROLES.admin">Admin</option>
                      <option :selected="getTeam.role===ROLES.collaborator" :value="ROLES.collaborator">Collaborator
                      </option>
                      <option :selected="getTeam.role===ROLES.guest" :value="ROLES.guest">Guest</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row" v-if="loadPermissions">
          <div class="mt-3 collaborator_roles"
               v-if="getTeam.role !== 'admin'">
            <div class="inner_container">
              <div class="permission_list">
                <ul>
                  <template v-if="getTeam.role === ROLES.collaborator">
                    <h3 class="sub_heading">Permissions</h3>

                    <li class="list_item">
                      <span>Allow adding new brands</span>
                      <div class="round_checkbox">
                        <input type="checkbox" id="canCreateBrands"
                               v-model="getTeam.permission.createBrand"/>
                        <label for="canCreateBrands"></label>
                      </div>
                    </li>

                    <li class="list_item">
                      <span>Allow to edit and delete assigned brands</span>
                      <div class="round_checkbox">
                        <input type="checkbox" id="canEditBrands"
                               v-model="getTeam.permission.editBrand"/>
                        <label for="canEditBrands"></label>
                      </div>
                    </li>

                    <li class="list_item">
                      <span>Allow viewing all Bio Links against selected brands</span>
                      <div class="round_checkbox">
                        <input type="checkbox" id="canViewAllBioLinks"
                               v-model="getTeam.permission.viewAllBioLinks"/>
                        <label for="canViewAllBioLinks"></label>
                      </div>
                    </li>

                  </template>

                  <div class="sub_heading_checkbox flex align-items-center justify-content-between"
                       v-if="checkTeamLimits(allBrands,getTeam.id,'brands')">
                    <p>Brands</p>
                    <div class="check_box flex align-items-center">
                      <p class="mr-2">Select all</p>
                      <input type="checkbox" @change="changeSelections('brands')" v-model="selections.brands" id="brands_select_all" value="false">
                      <label for="brands_select_all" class="brands_select_all"></label>
                    </div>
                  </div>

                  <template v-if="[ROLES.collaborator,ROLES.guest].includes(getTeam.role)">
                    <li class="list_item" v-for="brand in allBrands" v-if="getTeam.id !== brand.user_id">
                      <div class="profile_box_x clear">
                        <div class="d_icon" :style="brandImageLink(brand.avatar)">
                        </div>
                        <div class="d_content">
                          <p class="content_heading">
                            {{ brand.name }}
                            <span class="brand_name" v-if="brand.user_name">
                              By {{ brand.user_name.first_name }} {{ brand.user_name.last_name }}
                            </span>

                          </p>
                        </div>
                      </div>

                      <div class="round_checkbox">
                        <input type="checkbox" v-model="getTeam.permission.brands"
                               :id="brand._id"
                               :value="brand._id"
                        />
                        <label :for="brand._id"></label>
                      </div>

                    </li>
                  </template>

                  <template v-if="getTeam.role === ROLES.collaborator">

                    <div class="sub_heading_checkbox flex align-items-center justify-content-between"
                         v-if="checkTeamLimits(getPixelList,getTeam.id)">
                      <p>Pixels</p>
                      <div class="check_box flex align-items-center">
                        <p class="mr-2">Select all</p>
                        <input type="checkbox" @change="changeSelections('pixels')" v-model="selections.pixels" id="pixels_select_all" value="false">
                        <label for="pixels_select_all" class=""></label>
                      </div>
                    </div>

                    <li class="list_item" v-for="pixel in getPixelList" v-if="getTeam.id != pixel.user_id">

                      <div class="social_icons_square align_center">
                                                            <span class="icon pixel_icon"
                                                                  :class="getPixelSocialClass(pixel.type,1)">
                                                                <i
                                                                  :class="getPixelSocialClass(pixel.type,2)"></i>
                                                            </span>
                        <span class="text">{{
                            pixel.name
                          }} <span>{{ getGooglePixelOnKey(pixel.type, pixel.key, googlePixelsTypeKey) }}</span>
                                                                  <span class="brand_name" v-if="pixel.user_name">
                                                                        By {{ pixel.user_name.first_name }} {{
                                                                      pixel.user_name.last_name
                                                                    }}
                                                                    </span>
                                                            </span>
                      </div>

                      <div class="round_checkbox">
                        <input type="checkbox"
                               :id="pixel._id"
                               v-model="getTeam.permission.pixels"
                               :value="pixel._id"
                        />
                        <label :for="pixel._id"></label>
                      </div>

                    </li>

                    <div class="sub_heading_checkbox flex align-items-center justify-content-between"
                         v-if="checkTeamLimits(getDomains.items,getTeam.id)">
                      <p>Domains</p>
                      <div class="check_box flex align-items-center">
                        <p class="mr-2">Select all</p>
                        <input type="checkbox" @change="changeSelections('domains')" v-model="selections.domains" id="domains_select_all" value="false">
                        <label for="domains_select_all" class=""></label>
                      </div>
                    </div>

                    <li class="list_item" v-for="domain in getDomains.items"
                        v-if="getTeam.id !== domain.user_id">
                      <div class="profile_box_x clear">
                        <div class="d_content nopad-l">
                          <p class="content_heading domain_name">{{ domain.url }}
                            <span class="brand_name" v-if="domain.user_name">
                                                                    By {{ domain.user_name.first_name }} {{
                                domain.user_name.last_name
                              }}
                                                                </span>
                          </p>
                        </div>
                      </div>

                      <div class="round_checkbox">
                        <input type="checkbox"
                               :disabled="domain._id === getWhiteLabel.custom_domain_id"
                               v-model="getTeam.permission.domains"
                               :value="domain._id"
                               :id="domain._id"
                        />
                        <label
                          v-tooltip="domain._id === getWhiteLabel.custom_domain_id ? 'This domain is connected with your whitelabel.' : ''"
                          :class="domain._id === getWhiteLabel.custom_domain_id ? 'opacity-60' : ''"
                          :for="domain._id"></label>
                      </div>

                    </li>

                    <h3 v-if="getIntegrationCount()" class="sub_heading">Integrations</h3>
                    <div :key="index" v-for="(item,index) in integrations" class="integration_tree_main"
                         v-if="getProfile[item.key] && getProfile[item.key].length > 0">

                      <div class="sub_heading_int">
                        <img :src="[item.logo]" alt="">
                        <span>{{ item.name }}</span>
                      </div>
                      <li class="integration_tree list_item" :key="index" v-for="(list,index) in getProfile[item.key]">
                        <div class="integration_tree_item">
                          <div class="profile_box_x clear">
                            <div class="nopad-l d_content">
                              <p class="content_heading">
                                {{ list.name }}</p>
                            </div>
                          </div>
                        </div>

                        <div class="round_checkbox">
                          <template v-if="item.key=='mailchimp'">
                            <input type="checkbox"
                                   @click="TeamIntegrationPermission(list.user_id,item.key)"
                                   :checked="checkTeamSelection(list.user_id,getTeam.permission.integrations[item.key])"
                                   :id="list.user_id"/>
                            <label :for="list.user_id"></label>
                          </template>
                          <template v-else>
                            <input type="checkbox"
                                   @click="TeamIntegrationPermission(list.account_id,item.key)"
                                   :checked="checkTeamSelection(list.account_id,getTeam.permission.integrations[item.key])"
                                   :id="list.account_id"/>
                            <label :for="list.account_id"></label>
                          </template>
                        </div>
                      </li>
                    </div>

                    <h3 v-if="UTMs.length" class="sub_heading">UTMs</h3>
                    <div class="input_field multiple_select mt-2">
                      <multiselect v-model="getTeam.permission.utms" placeholder="Search or add a UTM"
                                   label="name" track-by="_id" :options="UTMs" :multiple="true">
                      </multiselect>
                    </div>
                  </template>

                </ul>
              </div>
            </div>

          </div>
        </div>
        <div class="row" v-if="!loadPermissions && getTeam.role === ROLES.collaborator">
          <div class="col-md-12 mt-5 text-center">
            <clip-loader :size="size" :color="color"></clip-loader>
          </div>
        </div>

      </form>

      <div class=" btn_block text-left">

        <button v-if="getUserLoader.inviteTeamMemberLoader"
                class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--right btn-bold">
          <span v-if="getTeam.id">Update</span>
          <span v-else>Invite</span>
          <clip-loader :size="size" :color="color"></clip-loader>
        </button>

        <button v-else @click.prevent="validateAndInviteTeamMember()"
                class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--right btn-bold">
          <span v-if="getTeam.id">Update</span>
          <span v-else>Invite</span>
        </button>

        <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold"
                @click="close()"><span>Close</span>
        </button>

      </div>

    </div>
  </b-modal>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import {DARK_PURPLE_LOADER_COLOR, ROLES} from '@/common/constants'
import http from '@/mixins/http-lib'
import Multiselect from 'vue-multiselect'
import {fetchIntegrationListURL} from '@/config/config'
import {googlePixelsTypeKey} from '@/common/attributes'

export default ({
  props: ['show_modal'],
  components: {
    Multiselect
  },
  data() {
    return {
      size: '17px',
      color: DARK_PURPLE_LOADER_COLOR,
      validations: {
        first_name: false,
        first_name_length: false,
        last_name: false,
        last_name_length: false,
        email: false,
        email_length: false
      },
      messages: {
        first_name: 'First name is required.',
        first_name_length: 'The first name should not be greater than 100 characters',
        last_name: 'Last name is required.',
        last_name_length: 'The last name should not be greater than 100 characters',
        email: 'Email address is required.',
        email_length: 'The email should not be greater than 100 characters'
      },
      loadPermissions: false,
      integrations: [],
      ROLES: ROLES,
      allBrands: [],
      UTMs: [],
      googlePixelsTypeKey: googlePixelsTypeKey,
      selections: {
        brands: true,
        pixels: true,
        domains: true
      }
    }
  },
  async created() {
    this.allBrands = await this.fetchBrandList({page: 1, isFetchAll: true})

    // component level loader implementation
    this.UTMs = await this.fetchAllUTMs()
    this.UTMs = this.UTMs.map(u => ({_id: u._id, name: `${u.campaign} - ${u.medium}`}))

    await this.fetchIntegrationList()
    await this.preSelectOptions()
    await this.setPreSelections()
  },
  mounted() {
    setTimeout(() => {
      this.loadPermissions = true
    }, 1000)
  },
  methods: {
    ...mapActions(['fetchBrandList', 'fetchAllUTMs']),
    /**
     * Method is responsible to fetch a list of integrations. All meta data related to the integrations to display it in integrations section
     */
    async fetchIntegrationList() {
      return http.post(fetchIntegrationListURL)
        .then(
          res => {
            if (res.data.status) {
              this.integrations = res.data.data.map(item => {
                return {
                  key: item.key,
                  logo: item.logo,
                  name: item.value,
                }
              })
            }
            return true
          },
          err => {
            this.alertMessage(err.message, 'error')
            this.integrations = []
          }
        )
    },
    /**
     * This method is responsible for if all the selections selected or not
     */
    setPreSelections() {
      this.selections = {
        brands: this.getTeam.permission.brands.length == this.allBrands.length,
        pixels: this.getTeam.permission.pixels.length == this.getPixelList.length,
        domains: this.getTeam.permission.domains.length == this.getDomains.items.length
      }
    },
    /**
     * This method is responsible for selecting or deselecting all the permissions
     * @param key
     */
    changeSelections(key= '') {
      if(key === 'brands') this.getTeam.permission.brands = this.selections.brands ? this.allBrands.map(brand => brand._id) : []
      if(key === 'pixels') this.getTeam.permission.pixels = this.selections.pixels ? this.getPixelList.map(brand => brand._id) : []
      if(key === 'domains') {
        let domains = this.getDomains.items.filter(item => item._id !== this.getWhiteLabel.custom_domain_id)
        let whitelabelDomainID = this.getDomains.items.find(item => item._id === this.getWhiteLabel.custom_domain_id)
        this.getTeam.permission.domains = this.selections.domains ? domains.map(brand => brand._id) : []
        this.getTeam.permission.domains.push(whitelabelDomainID._id)
      }
    },
    /**
     * In case of adding team member
     * Preselect all options (brands, pixels, domains) for collaborator and guest
     */
    preSelectOptions() {
      // it needs to auto-select for add mode only, not for edit-mode
      if (this.getTeam && this.getTeam.id) {

        // check if the utms exist
        if (this.getTeam.permission.utms) {
          const utm = this.UTMs.filter(u => this.getTeam.permission.utms.includes(u._id))
          this.getTeam.permission.utms = utm
        }
        return
      }
      if ([ROLES.collaborator, ROLES.guest].includes(this.getTeam.role)) {

        if (this.getTeam.role === ROLES.collaborator) {
          this.getTeam.permission.createBrand = true
          this.getTeam.permission.editBrand = true
          this.getTeam.permission.viewAllBioLinks = true

          // pixels
          this.getTeam.permission.pixels = this.getPixelList.map(pixel => pixel._id)

          // domains
          this.getTeam.permission.domains = this.getDomains.items.map(domain => domain._id)

          // integrations
          if (this.getIntegrationCount() > 0) {

            /**
             * Checking integrations
             */
            this.integrations.forEach(object => {
              const integration = object.key
              // integration exists
              if (this.getProfile[integration] && this.getProfile[integration].length) {
                // iterate on connected accounts of that integration
                this.getProfile[integration].forEach((integrationObject, index) => {
                  // Previously we were saving 'name' key in permission integrations for constantcontact.
                  // Now we would be saving account_id in case of constantcontact as well
                  const accountId = integration === 'mailchimp' ? integrationObject.user_id : integrationObject.account_id

                  // mark as selected if it is not already selected
                  if (!this.checkTeamSelection(accountId, this.getTeam.permission.integrations[integration])) {
                    this.TeamIntegrationPermission(accountId, integration)
                  }
                })
              }
            })
          }
        }
        // brands
        this.getTeam.permission.brands = this.allBrands.map(brand => brand._id)
      }
    },
    async validateAndInviteTeamMember() {
      this.validations.first_name = this.requiredCheck(this.getTeam.first_name)
      this.validations.last_name = this.requiredCheck(this.getTeam.last_name)
      this.validations.email = this.requiredCheck(this.getTeam.email)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (!result) {
        document.getElementById('modalAddTeam').scrollTop = 0
        return
      }

      let res = await this.$store.dispatch('inviteMember')
      if (!res) return
      if (!res.data.status) {
        this.$store.dispatch('toastNotification', {message: res.data.message, type: 'error'})
        return
      }
      this.$store.dispatch('toastNotification', {message: res.data.message})
      if (this.getTeam.id) {
        this.getTeamMember.item.splice(this.getTeamMember.item.findIndex(item => item._id === this.getTeam.id), 1, res.data.data)
      } else {
        this.getTeamMember.item.push(res.data.data)
      }

      this.close()
    },
    close() {
      this.$parent.closeAddTeamModal()
    }
  },
  computed: {
    ...mapGetters([
      'getUserLoader',
      'getTeam',
      'getProfile',
      'getPixelList',
      'getDomains',
      'getTeamMember',
      'getWhiteLabel'
    ])
  },
  watch: {
    'getTeam.first_name'(value) {
      if (value && value.length > 0) {
        this.validations.first_name = false
        this.validations.first_name_length = !this.maxLength(value, 100)
      }
    },
    'getTeam.last_name'(value) {
      if (value && value.length > 0) {
        this.validations.last_name = false
        this.validations.last_name_length = !this.maxLength(value, 100)
      }
    },
    'getTeam.email'(value) {
      if (value && value.length > 0) {
        this.validations.email = false
        this.validations.email_length = !this.maxLength(value, 100)
      }
    },
    'getTeam.role'(value) {
      if (value && value.length) {
        this.preSelectOptions()
      }
    },
    'getTeam.permission.brands' (value) {
      this.setPreSelections()
    },
    'getTeam.permission.pixels' (value) {
      this.setPreSelections()
    },
    'getTeam.permission.domains' (value) {
      this.setPreSelections()
    }
  }
})
</script>
