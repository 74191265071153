<template>
  <div class="box_content">

    <b-modal modal-class="modal_basic remove_team_member sm-modal" id="remove_team_member" centered no-close-on-backdrop
             hide-footer hide-header>
      <img @click="$bvModal.hide('remove_team_member')" class="close_modal" src="/assets/img/cross.svg" alt="">

      <div class="basic_form modal_content">

        <div class="heading txt-center">
          <h3>Remove Team Member</h3>
        </div>

        <div class="heading mb-4 txt-center">
          <p>Are you sure you want to remove your team member?</p>
        </div>

        <div v-if="getModalMemberStatus != -1" class="mb-4">
          <div class="checkbox-list">
            <div class="list-item d-flex align-items-center">
              <div class="radio_input_image">
                <input :checked="!getTeamMember.keepData" @click="getTeamMember.keepData = false" type="radio" name=""
                       id="cta_check_true">
                <label for="cta_check_true" class="no_text"></label>
              </div>
              <p class="text">Migrate team member's data to my account.</p>
              <i class="far fa-question-circle ml-1 custom_tooltip tag_tooltip" title="Platform">
                <div class="tool_tip_box">
                  <div class="inner_box">
                    <p>Brands, Campaigns, Links, Rss feeds, Bio Links, Tags, Custom Domain, Pixels, Custom Widgets,
                      UTMS,
                      and Black List Ip.</p>
                  </div>
                </div>
              </i>
            </div>

            <div class="list-item d-flex align-items-center">
              <div class="radio_input_image">
                <input :checked="getTeamMember.keepData" type="radio"
                       @click="getTeamMember.keepData = true" id="third_party_check" data-cy="custom-widget">
                <label for="third_party_check" class="no_text"></label>
              </div>
              <p class="text">Permanently delete team member's data.</p>
              <i class="far fa-question-circle ml-1 custom_tooltip tag_tooltip" title="Platform">
                <div class="tool_tip_box">
                  <div class="inner_box">
                    <p>Brands, Campaigns, Links, Rss feeds, Bio Links, Tags, Custom Domain, Pixels, Custom Widgets,
                      UTMS,
                      and Black List Ip.</p>
                  </div>
                </div>
              </i>
            </div>


          </div>

        </div>

        <div style="width: 55%" class="btn_block d-flex m-auto justify-content-around text-left">
          <button @click="$bvModal.hide('remove_team_member')" type="button"
                  class="ml-2 btn---cta light-blue btn-round  btn-bold"
          ><span>No, Cancel!</span></button>
          <button @click.prevent="removeMember"
                  class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--right btn-bold">
            <span>Yes, Delete it!</span>
          </button>
        </div>

      </div>
    </b-modal>

    <div class="table_container">
      <table class="table team-table list-content">

        <thead class=" ">
        <tr>
          <th style="width: 300px;">Profile</th>
          <th>Email</th>
          <th style="width: 150px;text-align: center;">Role</th>
          <th style="width: 163px;text-align: right;">Actions</th>
        </tr>
        </thead>

        <tbody class="list-content-body">
        <tr v-if="getTeamMember.loader">
          <td colspan="4">
            <div style="padding-top: 15px;">
              <clip-loader :size="size" :color="color"></clip-loader>
            </div>
          </td>
        </tr>

        <template v-else>
          <tr v-if="getTeamMember.item.length === 0">
            <td colspan="4">
              You do not have any team member added yet.
            </td>
          </tr>
          <tr v-for="member in getTeamMember.item" v-if="getTeamMember.item.length > 0">
            <td>
              <div class="profile_box_x clear">
                <div class="d_icon"
                     :style="{ 'background': 'url(' + backgroundImage(member) + ')' }">
                </div>
                <div class="d_content">
                  <p class="content_heading">{{ member.full_name }}</p>
                </div>
              </div>
            </td>
            <td>
              {{ member.email }}
              <span v-if="member.status === '-1'"
                    class="status invited_member gray">Invited</span>
            </td>
            <td style="text-align: center; white-space: nowrap">

              <span v-if="member.role === ROLES.admin" class="status blue">Admin</span>

              <span v-else-if="member.role === ROLES.collaborator" class="status purple">Collaborator</span>

              <span v-else-if="member.role === ROLES.guest" class="status purple">Guest</span>

              <span v-else class="status orange">Super Admin</span>

            </td>
            <td class="table-action " v-if="getProfile.policy.can_modify_team_members">

              <template v-if="member.status === '-1'">

                <i class="fal fa-clipboard" v-tooltip.top-center="'Copy Invitation link'"
                   v-if="$parent.invitationLink(member)"
                   v-clipboard:copy="$parent.invitationLink(member)"
                   v-clipboard:success="copyInvitationLinkSuccess"
                   v-clipboard:error="copyInvitationLinkError"
                ></i>

                <i class="far fa-refresh" v-tooltip.top-center="'Resend Invitation'"
                   @click.prevent="$store.dispatch('resendInvitation', member._id)"></i>

              </template>

              <i v-tooltip="'Edit'" v-if="member.role!==ROLES.super_admin" class="far fa-pencil "
                 @click.prevent="$parent.editTeamMemberModal(member)"
                 aria-hidden="true"></i>

              <i v-tooltip="'Remove'" v-if="member.role!==ROLES.super_admin" data-cy="t-remove"
                 @click.prevent="confirmAction(member._id,'remove_team_member',member.status)"
                 class="far fa-trash "
                 aria-hidden="true">
              </i>
            </td>
            <td v-else>
              <p class="text-right">-</p>
            </td>
          </tr>
        </template>

        </tbody>

      </table>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {DARK_PURPLE_LOADER_COLOR} from '../../../../common/constants'
import {ROLES} from '../../../../common/constants'

export default {
  data () {
    return {
      size: '17px',
      color: DARK_PURPLE_LOADER_COLOR,
      ROLES: ROLES
    }
  },
  computed: {
    ...mapGetters(['getTeamMember', 'getModalMemberStatus'])
  }
}
</script>

<style lang="less">
.status {
  width: 120px;
  overflow: hidden;
  display: block;
}

.remove_team_member {
  .modal-dialog {
    max-width: 600px !important;
    transform: translateY(50%);
  }
}
</style>
