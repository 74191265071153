<template>

  <div class="setting_form ">

    <add-team-member v-if="showAddTeamModal"></add-team-member>
    <div class="white_box  ">
      <div class="box_inner">
        <div class="box_head d-flex align-items-center">
          <div class="left">
            <h2>Team Members</h2>

          </div>
          <div class="right ml-auto">
            <guided-tour v-if="getGuidedTour.step10"></guided-tour>
            <template v-if="getProfile.policy.can_modify_team_members">
              <button v-if="getGuidedTour.step10" :class="{'activeTour' : getGuidedTour.step10}"
                      class="btn---cta btn-blue with-shadow btn-round   btn-bold"
                      @click.prevent="addTeamMemberModal()">
                <span>Add Team Member</span>
                <span class="icon_right">
                               <i
                                 class="circle_icon d-flex align-items-center justify-content-center  fal fa-plus  "></i>
                            </span>
              </button>
              <button v-else @click.prevent="addTeamMemberModal()"
                      class="btn---cta btn-blue with-shadow btn-round   btn-bold">
                <span>Add Team Member</span>
                <span class="icon_right">
                               <i
                                 class="circle_icon d-flex align-items-center justify-content-center  fal fa-plus  "></i>
                            </span>
              </button>
            </template>

          </div>

        </div>
        <team-members-list></team-members-list>

      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import GuidedTour from '../../../guided-tour/GuidedTour.vue'
import ConfirmationBox from '@/ui/ConfirmationBox.vue'
import {userTypes} from '@/state/modules/mutation-types'
import {baseURL} from '@/config/api'
import TeamMembersList from './TeamMembersList'
import AddTeamMember from './AddTeamMember'
import pick from 'lodash.pick'
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'

export default ({
  data
    () {
    return {
      size: '17px',
      color: DARK_PURPLE_LOADER_COLOR,
      showAddTeamModal: false
    }
  },
  components: {
    GuidedTour,
    ConfirmationBox,
    TeamMembersList,
    AddTeamMember

  },
  computed: {
    ...mapGetters([
      'getPixelList',
      'getGuidedTour',
      'getTeam',
      'getBrands',
      'getUserLoader',
      'getDomains',
      'getTeamMember',
      'getProfile',
      'getPixel'
    ])

  },
  async created () {
    this.getSiteDetails
    if (!this.getTeamMember.fetchStatus) await this.fetchTeamMembersList()
    if (!this.getPixel.fetchStatus) await this.fetchPixels()
    if (!this.getDomains.fetchStatus) await this.fetchDomains()
  },

  methods: {
    ...mapActions(['setAllBrandsFetchStatus', 'fetchPixels', 'fetchDomains', 'fetchTeamMembersList']),
    addTeamMemberModal () {
      this.$store.commit(userTypes.RESET_TEAM_ADD)

      if (this.getSubscription && this.getSubscription.limits &&
        (this.getSubscription.limits.team_members === false || this.getSubscription.limits.team_members_limit === 0)) {
        setTimeout(() => {
          this.$bvModal.show('upgrade-subscription-modal')
        }, 100)
        this.alertMessage('You have reached maximum limit of your subscription plan, please upgrade your plan to unlock more features.', 'error')
        return false
      }
      this.showAddTeamModal = true

      setTimeout(() => {
        this.$bvModal.show('modalAddTeam')
      }, 100)
      // $('#modalAddTeam').modal('show')
    },

    invitationLink (member) {
      console.debug('Method::invitationLink')
      return (member.member_token) ? baseURL + 'joinTeam/' + member.member_token : ''
    },

    editTeamMemberModal (member) {
      let data = JSON.parse(JSON.stringify(member))
      let selected = pick(data, ['email', 'email', 'role', 'first_name', 'last_name', 'permission'])
      selected.id = data._id
      console.debug(selected)
      this.$store.commit(userTypes.SET_TEAM_ADD_VALUE, selected)
      this.showAddTeamModal = true

      setTimeout(() => {
        this.$bvModal.show('modalAddTeam')
      }, 100)
    },
    closeAddTeamModal () {
      this.$bvModal.hide('modalAddTeam')
      this.showAddTeamModal = false
    }

  },
  watch: {

    // 'getTeam.role' (value) {
    //   let self = this
    //   console.debug(value, '------------------------------------------------------')
    //   setTimeout(function () {
    //     if (value == 0) {
    //       self.showTeamModelFotter(true)
    //     } else {
    //       self.showTeamModelFotter(false)
    //     }
    //   }, 100)
    // }
  }

})
</script>
